import { v4 as uuidv4 } from 'uuid';

class DataCenter {
  DEFAULT_CONFIGURATION: Configuration = {
    id: uuidv4(),
    announcementDe: '',
    announcementEn: '',
    announcementZn: '',
    telephone: '',
    document: undefined,
    workHours: [],
    emailDisable: false,
  };

  LANGUAGE_KEY = 'ln';

  LANGUAGE = {
    DE: 'de',
    EN: 'en',
    ZH: 'zh',
  };

  CART_KEY = 'xiang-cart';
  CART_CUSTOMER_KEY = 'xiang-customer';

  DEFAULT_CUSTOMER: Customer = {
    name: '',
    email: '',
    address: '',
    postal: '',
    city: 'München',
    telephone: '',
    message: '',
  };

  HOME_MENU = 'app.home';
  SPECIALITY_MENU = 'app.speciality';
  LUNCH_MENU = 'app.lunch';
  MEDIA_MENU = 'app.media';
  DISCOUNT_MENU = 'app.discount';
  CONTACT_MENU = 'app.contact';
  CART_MENU = 'app.cart';

  LUNCH_MENU_PATH = '/menu/menu.pdf';

  FOOD_ITEMS: SpecialityItem[] = [
    {
      id: 101,
      nameDe: 'Zizi Fisch',
      nameEn: 'Zizi Fisch',
      nameZn: '香辣烤鱼',
      categories: ['all', 'dryCook', 'main'],
      descriptionDe:
        'Gegrillter Wolfsbarsch serviert auf angebratenem Gemüse der Saison, Erdnüssen und erlesenen Gewürzen (scharf)',
      descriptionEn:
        'Roasted sea bass with seasonal vegetables, peanuts and selected spices (spicy) ',
      descriptionZn:
        'Roasted sea bass with seasonal vegetables, peanuts and selected spices (spicy) ',
      imgSrc: 'images/takeway/101.jpeg',
      price: 36.8,
      hot: 1,
      show: true,
    },
    {
      id: 102,
      nameDe: 'Gegrillter Fisch mit Eingelegten Chilischoten',
      nameEn: 'Gegrillter Fisch mit Eingelegten Chilischoten',
      nameZn: '剁椒烤鱼',
      categories: ['all', 'dryCook', 'main'],
      descriptionDe:
        'Gegrillter Wolfsbarsch mit eingelegten Chilischoten, serviert auf angebratenem Gemüse der Saison, Erdnüssen und erlesenen Gewürzen (scharf)',
      descriptionEn:
        'Roasted sea bass with pickled chillies, served on top of seasonal vegetables, peanuts and selected spices (spicy)',
      descriptionZn:
        'Roasted sea bass with pickled chillies, served on top of seasonal vegetables, peanuts and selected spices (spicy)',
      imgSrc: 'images/takeway/102.jpeg',
      price: 36.8,
      hot: 1,
      show: true,
    },
    {
      id: 104,
      nameDe: 'Gan-Guo Hähnchen',
      nameEn: 'Gan-Guo Hähnchen',
      nameZn: '干锅飘香鸡',
      categories: ['all', 'dryCook', 'main', 'meat'],
      descriptionDe:
        'Gebratene Hähnchenkeulen mit Gemüse nach Sichuan Art, serviert in Hot-Pot (sehr scharf)',
      descriptionEn:
        'Stir-fried diced chicken thighs with vegetables. Served in hot pot (Sichuan cuisine, very spicy)',
      descriptionZn:
        'Stir-fried diced chicken thighs with vegetables. Served in hot pot (Sichuan cuisine, very spicy)',
      imgSrc: 'images/takeway/104.jpeg',
      price: 18.8,
      hot: 2,
      show: true,
    },
    {
      id: 105,
      nameDe: 'Gan-Guo Ente mit Bier',
      nameEn: 'Gan-Guo Ente mit Bier',
      nameZn: '干锅啤酒鸭',
      categories: ['all', 'dryCook', 'main', 'meat'],
      descriptionDe:
        'Gebratene Ente mit Bier und Gemüse nach Sichuan Art, serviert in Hot-Pot (sehr scharf)',
      descriptionEn:
        'Stir-fried duck with beer and vegetables. Served in hot pot (Sichuan cuisine, very spicy)',
      descriptionZn:
        'Stir-fried duck with beer and vegetables. Served in hot pot (Sichuan cuisine, very spicy)',
      imgSrc: 'images/takeway/105.jpeg',
      price: 19.8,
      hot: 2,
      show: true,
    },
    {
      id: 106,
      nameDe: 'Gan-Guo Garnele',
      nameEn: 'Gan-Guo Garnele',
      nameZn: '干锅大虾',
      categories: ['all', 'dryCook', 'main', 'seaFood'],
      descriptionDe:
        'Gebratene Riesengarnelen mit Gemüse nach Sichuan Art, serviert im Hot-Pot (scharf)',
      descriptionEn:
        'Stir-fired jumbo prawns with vegetables, served in hot pot (Sichuan cuisine, spicy)',
      descriptionZn:
        'Stir-fired jumbo prawns with vegetables, served in hot pot (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/106.jpeg',
      price: 26.8,
      hot: 1,
      show: true,
    },
    {
      id: 107,
      nameDe: 'Gekochte Schweinrips',
      nameEn: 'Gekochte Schweinrips',
      nameZn: '干锅排骨',
      categories: ['all', 'dryCook', 'main'],
      descriptionDe:
        'Gebratene Schweinerippchen mil Gemuse nach Sichuan Art, serviert in Hot-Pot (scharf) Stir-fried Spareribs with vegetables served in hot pot (Sichuan cuisine, spicy)',
      descriptionEn:
        'Gebratene Schweinerippchen mil Gemuse nach Sichuan Art, serviert in Hot-Pot (scharf) Stir-fried Spareribs with vegetables served in hot pot (Sichuan cuisine, spicy)',
      descriptionZn:
        'Gebratene Schweinerippchen mil Gemuse nach Sichuan Art, serviert in Hot-Pot (scharf) Stir-fried Spareribs with vegetables served in hot pot (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/107.png',
      price: 19.8,
      hot: 1,
      show: true,
    },
    {
      id: 108,
      nameDe: 'Gan-Guo Ochsenfrösche',
      nameEn: 'Gan-Guo Ochsenfrösche',
      nameZn: '干锅牛蛙',
      categories: ['all', 'dryCook', 'main'],
      descriptionDe:
        'Gebratene Ochsenfrösche mit Gemüse nach Sichuan Art, serviert in Hot-Pot (scharf)',
      descriptionEn:
        'Stir-fired bullfrogs with vegetables, served in hot pot (Sichuan cuisine, very spicy)',
      descriptionZn:
        'Stir-fired bullfrogs with vegetables, served in hot pot (Sichuan cuisine, very spicy)',
      imgSrc: 'images/takeway/108.png',
      price: 19.8,
      hot: 1,
      show: true,
    },
    {
      id: 109,
      nameDe: 'Ma La Xiang Guo',
      nameEn: 'Ma La Xiang Guo',
      nameZn: '麻辣香锅',
      categories: ['all', 'dryCook', 'main'],
      descriptionDe:
        'Mir ausgewählten Lebensmitteln, nach Sichuan Art (scharf)',
      descriptionEn:
        'Spicy hot pot with selected foodstuffs (Sichuan cuisine, spicy)',
      descriptionZn:
        'Spicy hot pot with selected foodstuffs (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/109.jpeg',
      price: 26.8,
      hot: 1,
      show: true,
    },
    {
      id: 110,
      nameDe: 'Gan-Guo Kartoffelscheiben',
      nameEn: 'Gan-Guo Kartoffelscheiben',
      nameZn: '干锅土豆片',
      categories: ['all', 'dryCook', 'main'],
      descriptionDe:
        'Gebratene Kartoffelscheiben nach Sichuan Art, serviert in Hot-Pot (scharf)',
      descriptionEn:
        'Stir-fired potato slices served in hot pot (Sichuan cuisine, very spicy)',
      descriptionZn:
        'Stir-fired potato slices served in hot pot (Sichuan cuisine, very spicy)',
      imgSrc: 'images/takeway/110.jpeg',
      price: 14.8,
      hot: 1,
      show: true,
    },
    {
      id: 111,
      nameDe: 'Gan-Guo Blumenkohl',
      nameEn: 'Gan-Guo Blumenkohl',
      nameZn: '干锅花菜',
      categories: ['all', 'dryCook', 'main'],
      descriptionDe:
        'Gebratene Blumenkohl nach Sichuan Art, serviert in Hot-Pot (scharf)',
      descriptionEn:
        'Stir-fired cauliflower served in hot pot (Sichuan cuisine, spicy)',
      descriptionZn:
        'Stir-fired cauliflower served in hot pot (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/111.jpeg',
      price: 14.8,
      hot: 1,
      show: true,
    },
    {
      id: 201,
      nameDe: 'Koushui Hähnchen',
      nameEn: 'Koushui Hähnchen',
      nameZn: '口水鸡',
      categories: ['all', 'cold'],
      descriptionDe:
        'Gekochter Hähnchenschenkel serviert mit Kräutern und Erdnüssen in Sesam-Chiliöl-Soße nach Sichuan Art (scharf)',
      descriptionEn:
        'Cooked chicken thigh meat served with peanuts in homemade chili sesame oil sauce (Sichuan cuisine, spicy)',
      descriptionZn:
        'Cooked chicken thigh meat served with peanuts in homemade chili sesame oil sauce (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/201.jpeg',
      price: 9.8,
      hot: 1,
      show: true,
    },
    {
      id: 202,
      nameDe: 'Fu Qi Fei Pian',
      nameEn: 'Fu Qi Fei Pian',
      nameZn: '夫妻肺片',
      categories: ['all', 'cold'],
      descriptionDe:
        'Gekochte Kutteln, Rinderlunge, Rinderherz und Rinderzunge in Sesam-Chiliöl-Soße nach Sichuan Art (scharf)',
      descriptionEn:
        'Cooked tripe, beefing, beef heart, and beef tongue in homemade chili sesame oil sauce (Sichuan cuisine, spicy)',
      descriptionZn:
        'Cooked tripe, beefing, beef heart, and beef tongue in homemade chili sesame oil sauce (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/202.jpeg',
      price: 9.8,
      hot: 1,
      show: true,
    },
    {
      id: 203,
      nameDe: 'Hong You Shun Feng',
      nameEn: 'Hong You Shun Feng',
      nameZn: '红油顺风',
      categories: ['all', 'cold'],
      descriptionDe:
        'Gekochte Schweineohren serviert in Chiliöl-Soße nach Sichuan Art',
      descriptionEn:
        'Cooked pig ears served in homemade chili sesame oil sauce (Sichuan cuisine, spicy)',
      descriptionZn:
        'Cooked pig ears served in homemade chili sesame oil sauce (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/203.jpeg',
      price: 9.8,
      hot: 1,
      show: true,
    },
    {
      id: 204,
      nameDe: 'Tausendjährige Eier',
      nameEn: 'Tausendjährige Eier',
      nameZn: '尖椒皮蛋',
      categories: ['all', 'cold'],
      descriptionDe:
        'Tausendjährige Eier mit gebratener Spitzpaprika in Essig-Sojasoße',
      descriptionEn:
        'Preserved egg with fried green pointed peppers in homemade sauce',
      descriptionZn:
        'Preserved egg with fried green pointed peppers in homemade sauce',
      imgSrc: 'images/takeway/204.jpeg',
      price: 9.8,
      show: true,
    },
    {
      id: 205,
      nameDe: 'Bohnengelee-Nudeln',
      nameEn: 'Bohnengelee-Nudeln',
      nameZn: '川北凉粉',
      categories: ['all', 'cold'],
      descriptionDe:
        'Breite Nudeln aus Bohnen serviert in Chiliöl-Soße nach Chuanbei Art (scharf)',
      descriptionEn:
        'Cold bean jelly noodles served with peanuts in homemade chili sesame oil sauce (spicy)',
      descriptionZn:
        'Cold bean jelly noodles served with peanuts in homemade chili sesame oil sauce (spicy)',
      imgSrc: 'images/takeway/205.jpeg',
      price: 9.8,
      hot: 1,
      show: true,
    },
    {
      id: 206,
      nameDe: 'Mu-Er-Pilz Salat',
      nameEn: 'Mu-Er-Pilz Salat',
      nameZn: '凉拌木耳',
      categories: ['all', 'cold'],
      descriptionDe: 'Mu-Er-Pilze mit Knoblauch, Koriander und Vinaigrette',
      descriptionEn: 'Wood ear fungus with garlic, sesame oil and vinaigrette',
      descriptionZn: 'Wood ear fungus with garlic, sesame oil and vinaigrette',
      imgSrc: 'images/takeway/206.jpeg',
      price: 8.8,
      show: true,
    },
    {
      id: 207,
      nameDe: 'Gurkendalat',
      nameEn: 'Gurkendalat',
      nameZn: '凉拌黄瓜',
      categories: ['all', 'cold'],
      descriptionDe: 'Gurken mit Essig, Sesamöl und Knoblauch',
      descriptionEn: 'Cucumber with garlic, sesame oil and vinaigrette',
      descriptionZn: 'Cucumber with garlic, sesame oil and vinaigrette',
      imgSrc: 'images/takeway/207.jpeg',
      price: 8.8,
      show: true,
    },
    {
      id: 208,
      nameDe: 'Frittierte Erdnüsse',
      nameEn: 'Frittierte Erdnüsse',
      nameZn: '油炸花生米',
      categories: ['all', 'cold'],
      descriptionDe: '',
      descriptionEn: 'Deep fried peanuts',
      descriptionZn: 'Deep fried peanuts',
      imgSrc: 'images/takeway/208.jpeg',
      price: 7.8,
      show: true,
    },
    {
      id: 209,
      nameDe: 'Edamame Salat',
      nameEn: 'Edamame Salat',
      nameZn: '爽脆毛豆',
      categories: ['all', 'cold'],
      descriptionDe: '',
      descriptionEn: 'Soybean salad',
      descriptionZn: 'Soybean salad',
      imgSrc: 'images/takeway/209.jpeg',
      price: 7.8,
      show: true,
    },
    {
      id: 301,
      nameDe: 'Xiang Fisch',
      nameEn: 'Xiang Fisch',
      nameZn: '剁椒鱼',
      categories: ['all', 'main'],
      descriptionDe:
        'Ganzer Wolfbarsch gedämpft mit eingelegten Chilischoten nach Hunan Art (scharf)',
      descriptionEn:
        'Whole sea bass steamed with pickled chillies (Xiang cuisine, spicy)',
      descriptionZn:
        'Whole sea bass steamed with pickled chillies (Xiang cuisine, spicy)',
      imgSrc: 'images/takeway/301.jpeg',
      price: 22.8,
      hot: 1,
      show: true,
    },
    {
      id: 302,
      nameDe: 'Fen-Zheng Garnelen',
      nameEn: 'Fen-Zheng Garnelen',
      nameZn: '粉丝蒸大虾',
      categories: ['all', 'seaFood', 'main'],
      descriptionDe:
        'Gedämpfte Garnelen mit Glasnudeln, serviert mit Frühlingszwiebeln und Knoblauch in einer Soße aus Soja-Soße und heißem Planzenöl',
      descriptionEn:
        'Steamed jumbo prawns with glass noodle, served with spring onions and garlic in a sauce consists of soy sauce and hot vegetable oil',
      descriptionZn:
        'Steamed jumbo prawns with glass noodle, served with spring onions and garlic in a sauce consists of soy sauce and hot vegetable oil',
      imgSrc: 'images/takeway/302.jpeg',
      price: 25.8,
      show: true,
    },
    {
      id: 303,
      nameDe: 'Qing-Zheng Wolfsbarsch',
      nameEn: 'Qing-Zheng Wolfsbarsch',
      nameZn: '清蒸鲈鱼',
      categories: ['all', 'main'],
      descriptionDe:
        'Gedämpfter Wolfsbarsch, serviert mit Frühlingszwiebeln und Ingwer in einer Soße aus Soja-Soße und heißem Pflanzenöl',
      descriptionEn:
        'Steamed whole sea bass, served with scallions and ginger in a sauce of soy sauce and hot vegetable oil',
      descriptionZn:
        'Steamed whole sea bass, served with scallions and ginger in a sauce of soy sauce and hot vegetable oil',
      imgSrc: 'images/takeway/303.jpeg',
      price: 20.8,
      show: true,
    },
    {
      id: 304,
      nameDe: 'Mei-Cai Schweinebauch',
      nameEn: 'Mei-Cai Schweinebauch',
      nameZn: '梅菜扣肉',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Scheiben von geschmortem Schweinebauch auf gepökeltem Kohl',
      descriptionEn: 'Slices of braised pork belly on pickled cabbage',
      descriptionZn: 'Slices of braised pork belly on pickled cabbage',
      imgSrc: 'images/takeway/304.jpeg',
      price: 16.8,
      show: true,
    },
    {
      id: 307,
      nameDe: 'Gedämpfte Eier',
      nameEn: 'Gedämpfte Eier',
      nameZn: '虾仁蒸蛋',
      categories: ['all', 'main'],
      descriptionDe: 'Gedämpfte Eier mit geschälten Garnelen',
      descriptionEn: 'Steamed eggs with shrimp meat',
      descriptionZn: 'Steamed eggs with shrimp meat',
      imgSrc: 'images/takeway/307.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 401,
      nameDe: 'Krebs mit Frühlingszwiebeln und Ingwer',
      nameEn: 'Krebs mit Frühlingszwiebeln und Ingwer',
      nameZn: '葱姜蟹',
      categories: ['all', 'seaFood', 'main'],
      descriptionDe: 'Gebratene Krebse mit Frühlingszwiebeln und Ingwer',
      descriptionEn: 'Fried crab with green onions and ginger',
      descriptionZn: 'Fried crab with green onions and ginger',
      imgSrc: 'images/takeway/401.jpeg',
      price: 26.8,
      show: true,
    },
    {
      id: 402,
      nameDe: 'Xiang-La Krebse',
      nameEn: 'Xiang-La Krebse',
      nameZn: '香辣蟹',
      categories: ['all', 'seaFood', 'main'],
      descriptionDe:
        'Gebratene Krebse mit trockenen Chilischoten und Knoblauch nach Sichuan-Art (scharf)',
      descriptionEn:
        'Fried crab with dry chilies and garlic (Chuan cuisine, very spicy)',
      descriptionZn:
        'Fried crab with dry chilies and garlic (Chuan cuisine, very spicy)',
      imgSrc: 'images/takeway/402.jpeg',
      price: 26.8,
      hot: 1,
      show: true,
    },
    {
      id: 403,
      nameDe: 'Pochierter Fisch',
      nameEn: 'Pochierter Fisch',
      nameZn: '水煮鱼',
      categories: ['all', 'main'],
      descriptionDe:
        'Kleine Fischfilets eingebettet auf Gemüse in scharfer Brühe mit erlesenen Gewürzen aus Sichuan (sehr scharf)',
      descriptionEn:
        'Small fish fillets embedded on vegetables in a spicy broth with selected spices of Sichuan (Sichuan cuisine, very spicy)',
      descriptionZn:
        'Small fish fillets embedded on vegetables in a spicy broth with selected spices of Sichuan (Sichuan cuisine, very spicy)',
      imgSrc: 'images/takeway/403.jpeg',
      price: 20.8,
      hot: 2,
      show: true,
    },
    {
      id: 404,
      nameDe: 'Kung Pao Hähnchen',
      nameEn: 'Kung Pao Hähnchen',
      nameZn: '宫保鸡丁',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Würzig gebratenes Hühnerfleisch mit Zucchini, Karotten, Erdnüssen und Chilischoten nach Sichuan-Art (scharf)',
      descriptionEn:
        'Spicy stir-fried diced chicken thighs with peanuts, vegetable and chili peppers (Chuan cuisine, spicy)',
      descriptionZn:
        'Spicy stir-fried diced chicken thighs with peanuts, vegetable and chili peppers (Chuan cuisine, spicy)',
      imgSrc: 'images/takeway/404.jpeg',
      price: 17.8,
      hot: 1,
      show: true,
    },
    {
      id: 405,
      nameDe: 'La-Zi Hähnchen',
      nameEn: 'La-Zi Hähnchen',
      nameZn: '辣子鸡',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Gebratene Hähnchenkeulen mit getrockneten Chilischoten und Knoblauch nach Sichuan-Art (scharf)',
      descriptionEn:
        'Stir-fried diced chicken thighs with dried chilies and garlic (Sichuan cuisine, spicy)',
      descriptionZn:
        'Stir-fried diced chicken thighs with dried chilies and garlic (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/405.jpeg',
      price: 17.8,
      hot: 1,
      show: true,
    },
    {
      id: 406,
      nameDe: 'Xiang Flusskrebs',
      nameEn: 'Xiang Flusskrebs',
      nameZn: '口味小龙虾',
      categories: ['all', 'seaFood', 'main'],
      descriptionDe: 'Gebratene Flusskrebs nach Hunan Art (scharf)',
      descriptionEn: 'Stir-fired crayfish (Hunan cuisine, spicy)',
      descriptionZn: 'Stir-fired crayfish (Hunan cuisine, spicy)',
      imgSrc: 'images/takeway/406.jpeg',
      price: 24.8,
      hot: 1,
      show: true,
    },
    {
      id: 407,
      nameDe: 'Hong-Shao Schweinefleisch',
      nameEn: 'Hong-Shao Schweinefleisch',
      nameZn: '毛氏红烧肉',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Schweinebauch mit Ingwer, Knoblauch, aromatischen Gewürzen in heller und dunkler Sojasoße und Reiswein geschmort',
      descriptionEn:
        'Stewed pork belly with ginger, garlic, aromatic spices and soy sauce and rice wine',
      descriptionZn:
        'Stewed pork belly with ginger, garlic, aromatic spices and soy sauce and rice wine',
      imgSrc: 'images/takeway/407.jpeg',
      price: 16.8,
      show: true,
    },
    {
      id: 409,
      nameDe: 'Xiang-Nan Schweinebauch',
      nameEn: 'Xiang-Nan Schweinebauch',
      nameZn: '湘南小炒肉',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Gebratene Schweinebauchscheiben mit Paprika nach Xiang-nan-Art (leicht scharf)',
      descriptionEn:
        'Stir-fried pork belly slices with green pointed peppers (Xiang cuisine, spicy)',
      descriptionZn:
        'Stir-fried pork belly slices with green pointed peppers (Xiang cuisine, spicy)',
      imgSrc: 'images/takeway/409.jpeg',
      price: 17.8,
      hot: 1,
      show: true,
    },
    {
      id: 410,
      nameDe: 'Zweimal gebratener Schweinefleisch',
      nameEn: 'Zweimal gebratener Schweinefleisch',
      nameZn: '回锅肉',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Geschmorter Schweinebauch in kleinen Scheiben gebraten mit Kohlgemüse, Paprika, Frühlingszwiebeln nach Sichuan-Art (scharf)',
      descriptionEn:
        'Simmered pork belly then sliced and stir fried with cabbage, bell pepper, spring onions (Chuan cuisine, very spicy)',
      descriptionZn:
        'Simmered pork belly then sliced and stir fried with cabbage, bell pepper, spring onions (Chuan cuisine, very spicy)',
      imgSrc: 'images/takeway/410.jpeg',
      price: 17.8,
      hot: 1,
      show: true,
    },
    {
      id: 411,
      nameDe: 'Qin-Chao Krabben',
      nameEn: 'Qin-Chao Krabben',
      nameZn: '清炒虾仁',
      categories: ['all', 'seaFood', 'main'],
      descriptionDe: 'Mild gebratene Garnelen mit einem leichten Dip',
      descriptionEn: 'Stir fried shelled shrimps with light sauce',
      descriptionZn: 'Stir fried shelled shrimps with light sauce',
      imgSrc: 'images/takeway/411.jpeg',
      price: 19.8,
      show: true,
    },
    {
      id: 412,
      nameDe: 'Moo-Shu Schweinefleisch',
      nameEn: 'Moo-Shu Schweinefleisch',
      nameZn: '木须肉',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Gebratene Schweinefiletstreifen mit dünn geschnittenen, Tageslilienknospen,Mu-Er-Pilzen und Eiern',
      descriptionEn:
        'Stir fried shredded pork chop meat and scrambled eggs with sesame oil, thinly sliced wood ear fungus and day lily buds',
      descriptionZn:
        'Stir fried shredded pork chop meat and scrambled eggs with sesame oil, thinly sliced wood ear fungus and day lily buds',
      imgSrc: 'images/takeway/412.jpeg',
      price: 16.8,
      show: true,
    },
    {
      id: 413,
      nameDe: 'Mao Xue Wang',
      nameEn: 'Mao Xue Wang',
      nameZn: '毛血旺',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Gekochte Schwein Blut, Kutteln und Frühstücksfleisch in scharfer Brühe mit erlesenen Gewürzen aus Sichuan (scharf)',
      descriptionEn:
        'Cooked pig blood, tripe, luncheon meat, etc. in a spicy broth with selected spices of Sichuan (spicy)',
      descriptionZn:
        'Cooked pig blood, tripe, luncheon meat, etc. in a spicy broth with selected spices of Sichuan (spicy)',
      imgSrc: 'images/takeway/413.jpeg',
      price: 27.8,
      hot: 1,
      show: true,
    },
    {
      id: 414,
      nameDe: 'Yu-Xiang Schweinefleisch',
      nameEn: 'Yu-Xiang Schweinefleisch',
      nameZn: '鱼香肉丝',
      categories: ['all', 'main'],
      descriptionDe:
        'Gebratene Schweinelendenstreifen mit Gemüse in Yuxiang-Soße mit Knoblauch, Schalotten und Ingwer nach Sichuan-Art (leicht süß und scharf)',
      descriptionEn:
        'Stir-fried pork tenderloin strips in homemade Yuxiang sauce made from garlic, shallots and ginger (slightly sweet and spicy)',
      descriptionZn:
        'Stir-fried pork tenderloin strips in homemade Yuxiang sauce made from garlic, shallots and ginger (slightly sweet and spicy)',
      imgSrc: 'images/takeway/414.jpeg',
      price: 16.8,
      show: true,
    },
    {
      id: 415,
      nameDe: 'Gebratene Glasnudeln',
      nameEn: 'Gebratene Glasnudeln',
      nameZn: '蚂蚁上树',
      categories: ['all', 'main'],
      descriptionDe:
        'Gebratene Glasnudeln mit Hackfleisch, Sojasoße und Chilipaste (leicht scharf)',
      descriptionEn:
        'Stir-fried glass noodle with ground port, rice vinegar, soy sauce, vegetable oil, sesame oil, scallions, garlic, ginger and chili paste (Chuan cuisine, slightly spicy)',
      descriptionZn:
        'Stir-fried glass noodle with ground port, rice vinegar, soy sauce, vegetable oil, sesame oil, scallions, garlic, ginger and chili paste (Chuan cuisine, slightly spicy)',
      imgSrc: 'images/takeway/415.jpeg',
      price: 14.8,
      show: true,
    },
    {
      id: 416,
      nameDe: 'Jing-Jiang Schweinefleisch',
      nameEn: 'Jing-Jiang Schweinefleisch',
      nameZn: '京酱肉丝',
      categories: ['all', 'main'],
      descriptionDe:
        'Gebratenes Schweinefleisch mit süßer Bohnenpaste nach Peking Art, serviert mit Frühlingszwiebeln, Gurken und Pfannkuchen',
      descriptionEn:
        'Sautéed shredded pork with sweet bean sauce, served with scallions, cucumber and pancake',
      descriptionZn:
        'Sautéed shredded pork with sweet bean sauce, served with scallions, cucumber and pancake',
      imgSrc: 'images/takeway/416.jpeg',
      price: 17.8,
      show: true,
    },
    {
      id: 417,
      nameDe: 'Xiao-Xiang Schweinefüße',
      nameEn: 'Xiao-Xiang Schweinefüße',
      nameZn: '潇湘猪手',
      categories: ['all', 'main'],
      descriptionDe: 'Geschmorte Schweinefüße nach Xiang Art (leicht scharf)',
      descriptionEn: 'Braised Pork feet (Xiang cuisine, slightly spicy)',
      descriptionZn: 'Braised Pork feet (Xiang cuisine, slightly spicy)',
      imgSrc: 'images/takeway/417.jpeg',
      price: 16.8,
      show: true,
    },
    {
      id: 418,
      nameDe: 'Gebratener Schweinedickdarm mit Spitzpaprika',
      nameEn: 'Gebratener Schweinedickdarm mit Spitzpaprika',
      nameZn: '尖椒炒肥肠',
      categories: ['all', 'meat', 'main'],
      descriptionDe: '',
      descriptionEn: 'Stir-fried pork intestine with green chili peppers',
      descriptionZn: 'Stir-fried pork intestine with green chili peppers',
      imgSrc: 'images/takeway/418.jpeg',
      price: 18.8,
      show: true,
    },
    {
      id: 419,
      nameDe: 'Xiang-Gan Schweinebauch',
      nameEn: 'Xiang-Gan Schweinebauch',
      nameZn: '小炒香干',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Gebratene gepökeltes Schweinebauch mit Räuchertofu nach Hunan Art (leicht scharf)',
      descriptionEn:
        'Stir-fried salted pork with smoked tofu (Hunan cuisine, slightly spicy)',
      descriptionZn:
        'Stir-fried salted pork with smoked tofu (Hunan cuisine, slightly spicy)',
      imgSrc: 'images/takeway/419.jpeg',
      price: 17.8,
      show: true,
    },
    {
      id: 420,
      nameDe: 'Pochiertes Rindfleisch',
      nameEn: 'Pochiertes Rindfleisch',
      nameZn: '水煮牛肉',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Rindfleisch eingebettet auf Gemüse in scharfer Brühe mit erlesenen Gewürzen aus Sichuan (sehr scharf)',
      descriptionEn:
        'Sliced beef embedded on vegetables in a spicy broth with selected spices of Sichuan (Chuan cuisine, very spicy)',
      descriptionZn:
        'Sliced beef embedded on vegetables in a spicy broth with selected spices of Sichuan (Chuan cuisine, very spicy)',
      imgSrc: 'images/takeway/420.jpeg',
      price: 19.8,
      hot: 2,
      show: true,
    },
    {
      id: 421,
      nameDe: 'Lammfleisch mit Kreuzkümmel ',
      nameEn: 'Lammfleisch mit Kreuzkümmel ',
      nameZn: '孜然羊肉',
      categories: ['all', 'meat', 'main'],
      descriptionDe: 'Gebratenes Lammfleisch mit Zwiebeln und Kreuzkümmel',
      descriptionEn: 'fried lamb Gebratenes with onions and cumin',
      descriptionZn: 'fried lamb Gebratenes with onions and cumin',
      imgSrc: 'images/takeway/421.jpeg',
      price: 19.8,
      hot: 1,
      show: true,
    },
    {
      id: 422,
      nameDe: 'MapoTofu',
      nameEn: 'MapoTofu',
      nameZn: '麻婆豆腐',
      categories: ['all', 'main'],
      descriptionDe:
        'Gebratener Tofu mit Hackfleisch und ausgesuchten Gewürzen aus Sichuan (scharf)',
      descriptionEn:
        'Fried tofu with ground pork and original spices selected from Sichuan (Sichuan cuisine, spicy)',
      descriptionZn:
        'Fried tofu with ground pork and original spices selected from Sichuan (Sichuan cuisine, spicy)',
      imgSrc: 'images/takeway/422.jpeg',
      price: 14.8,
      hot: 1,
      show: true,
    },
    {
      id: 423,
      nameDe: 'Rindfleisch mit schwarzem Pfeffer',
      nameEn: 'Rindfleisch mit schwarzem Pfeffer',
      nameZn: '黑椒牛柳',
      categories: ['all', 'meat', 'main'],
      descriptionDe:
        'Gebratenes Rindfleisch mit schwarzem Pfeffer, Zwiebeln, Champignons und Paprika',
      descriptionEn:
        'Stir fried beef with black pepper, onions, mushrooms and bell pepper ',
      descriptionZn:
        'Stir fried beef with black pepper, onions, mushrooms and bell pepper ',
      imgSrc: 'images/takeway/423.jpeg',
      price: 18.8,
      show: true,
    },
    {
      id: 424,
      nameDe: 'Gebratene Eier mit Tomaten',
      nameEn: 'Gebratene Eier mit Tomaten',
      nameZn: '番茄炒蛋',
      categories: ['all', 'main'],
      descriptionDe: '',
      descriptionEn: 'Stir-fried eggs with tomatoes',
      descriptionZn: 'Stir-fried eggs with tomatoes',
      imgSrc: 'images/takeway/424.jpeg',
      price: 14.8,
      show: true,
    },
    {
      id: 425,
      nameDe: 'Di-San-Xian',
      nameEn: 'Di-San-Xian',
      nameZn: '地三鲜',
      categories: ['all', 'vegetable'],
      descriptionDe:
        'Gebratenes Dreierlei von Aubergine, Paprika und Kartoffel',
      descriptionEn: 'Fried eggplant, peppers and potatoes',
      descriptionZn: 'Fried eggplant, peppers and potatoes',
      imgSrc: 'images/takeway/425.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 426,
      nameDe: 'Gebratene Aubergine und Bohnen',
      nameEn: 'Gebratene Aubergine und Bohnen',
      nameZn: '豆角茄子',
      categories: ['all', 'vegetable'],
      descriptionDe: '',
      descriptionEn: 'Stir-fired eggplant and green beans',
      descriptionZn: 'Stir-fired eggplant and green beans',
      imgSrc: 'images/takeway/426.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 427,
      nameDe: 'Yuxiang Aubergine',
      nameEn: 'Yuxiang Aubergine',
      nameZn: '鱼香茄子',
      categories: ['all', 'vegetable'],
      descriptionDe:
        'Gebratene Aubergine in Yuxiang-Soße mit Knoblauch, Schalotten und Ingwer nach Sichuan-Art (leicht süß und scharf)',
      descriptionEn:
        'Stir-fried eggplant in homemade Yuxiang sauce made from garlic, shallots and ginger (slightly sweet, sauer and spicy)',
      descriptionZn:
        'Stir-fried eggplant in homemade Yuxiang sauce made from garlic, shallots and ginger (slightly sweet, sauer and spicy)',
      imgSrc: 'images/takeway/427.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 428,
      nameDe: 'Gebratene Bohnen',
      nameEn: 'Gebratene Bohnen',
      nameZn: '干煸四季豆/豆角',
      categories: ['all', 'vegetable'],
      descriptionDe:
        'Gebratene Bohnen mit Knoblauch und trocken Chilischoten (scharf)',
      descriptionEn:
        'Dry-fried green beens with Gebratene Bohnen mit Garlic and dry chillies (slightly spicy)',
      descriptionZn:
        'Dry-fried green beens with Gebratene Bohnen mit Garlic and dry chillies (slightly spicy)',
      imgSrc: 'images/takeway/428.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 429,
      nameDe: 'Jia-ChangTofu',
      nameEn: 'Jia-ChangTofu',
      nameZn: '家常豆腐',
      categories: ['all', 'main'],
      descriptionDe: 'Omas Tofu mit Gemüse',
      descriptionEn: 'Fried tofu, home style, with vegetables',
      descriptionZn: 'Fried tofu, home style, with vegetables',
      imgSrc: 'images/takeway/430.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 430,
      nameDe: 'Chao-He-Cai',
      nameEn: 'Chao-He-Cai',
      nameZn: '炒合菜',
      categories: ['all', 'vegetable'],
      descriptionDe: 'Mit Eier, Glasnudeln und Sojasprossen',
      descriptionEn: 'Stir-fired eggs, glass noodle and bean sprouts',
      descriptionZn: 'Stir-fired eggs, glass noodle and bean sprouts',
      imgSrc: 'images/takeway/431.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 502,
      nameDe: 'Gebratene Kartoffelstreifen',
      nameEn: 'Gebratene Kartoffelstreifen',
      nameZn: '尖椒土豆丝',
      categories: ['all', 'vegetable'],
      descriptionDe: 'Gebratene Kartoffelstreifen mit Spitzpaprika',
      descriptionEn: 'Stir-fried potato strips with green chili peppers',
      descriptionZn: 'Stir-fried potato strips with green chili peppers',
      imgSrc: 'images/takeway/502.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 503,
      nameDe: 'Gebratene Spitzkohl',
      nameEn: 'Gebratene Spitzkohl',
      nameZn: '手撕包菜',
      categories: ['all', 'vegetable'],
      descriptionDe:
        'Gebratene Spitzkohl mit Knoblauch und Chilischoten (leicht scharf)',
      descriptionEn:
        'Stir-fried cabbage with garlic and dry chillies (slightly spicy)',
      descriptionZn:
        'Stir-fried cabbage with garlic and dry chillies (slightly spicy)',
      imgSrc: 'images/takeway/503.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 504,
      nameDe: 'Gebratener Pak Choi',
      nameEn: 'Gebratener Pak Choi',
      nameZn: '清炒小油菜',
      categories: ['all', 'vegetable'],
      descriptionDe: 'Gebratener Pak Choi',
      descriptionEn: 'Stir-fried Pak Choi',
      descriptionZn: 'Stir-fried Pak Choi',
      imgSrc: 'images/takeway/504.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 506,
      nameDe: 'Cu-Liu Chinakohl',
      nameEn: 'Cu-Liu Chinakohl',
      nameZn: '醋溜大白菜',
      categories: ['all', 'vegetable'],
      descriptionDe: 'Gebratene Chinakohl mit Essig und Spitzpaprika',
      descriptionEn:
        'Stir-fired Chinese cabbage with vinegar and red chilli peppers',
      descriptionZn:
        'Stir-fired Chinese cabbage with vinegar and red chilli peppers',
      imgSrc: 'images/takeway/506.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 507,
      nameDe: 'Tiger-Spitzpaprika',
      nameEn: 'Tiger-Spitzpaprika',
      nameZn: '虎皮尖椒',
      categories: ['all', 'vegetablen'],
      descriptionDe:
        'Gebratene Spitzpaprika serviert mit einem hausgemachten Dressing (leicht scharf)',
      descriptionEn: 'Pan-seared green chili pepper (slightly spicy)',
      descriptionZn: 'Pan-seared green chili pepper (slightly spicy)',
      imgSrc: 'images/takeway/507.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 508,
      nameDe: 'He-Tang-XIao-Chao',
      nameEn: 'He-Tang-XIao-Chao',
      nameZn: '荷塘小炒',
      categories: ['all', 'vegetable'],
      descriptionDe: 'Gebratener buntem Gemüse',
      descriptionEn: 'Stir-fired various seasonal vegetables',
      descriptionZn: 'Stir-fired various seasonal vegetables',
      imgSrc: 'images/takeway/508.jpeg',
      price: 18.8,
      show: true,
    },
    {
      id: 602,
      nameDe: 'Schweinerippchen-Suppe',
      nameEn: 'Schweinerippchen-Suppe',
      nameZn: '海带排骨汤',
      categories: ['all', 'soup'],
      descriptionDe: 'Schweinerippchen-Suppe mit Seetang',
      descriptionEn: 'Spareribs soup with seaweed',
      descriptionZn: 'Spareribs soup with seaweed',
      imgSrc: 'images/takeway/602.jpeg',
      price: 16.8,
      show: true,
    },
    {
      id: 603,
      nameDe: 'Eiersuppe mit Seatang',
      nameEn: 'Eiersuppe mit Seatang',
      nameZn: '紫菜蛋花汤',
      categories: ['all', 'soup'],
      descriptionDe: 'Eiersuppe mit Seetang (für 4 Leute)',
      descriptionEn: 'Egg drop soup with seaweed (for 4 people)',
      descriptionZn: 'Egg drop soup with seaweed (for 4 people)',
      imgSrc: 'images/takeway/603.jpeg',
      price: 11.8,
      show: true,
    },
    {
      id: 604,
      nameDe: 'Eiersuppe mit Tomaten',
      nameEn: 'Eiersuppe mit Tomaten',
      nameZn: '西红柿蛋花汤',
      categories: ['all', 'soup'],
      descriptionDe: 'Eiersuppe mit Tomaten (für 4 Leute)',
      descriptionEn: 'Egg drop soup with tomatoes (for 4 people)',
      descriptionZn: 'Egg drop soup with tomatoes (for 4 people)',
      imgSrc: 'images/takeway/604.jpeg',
      price: 11.8,
      show: true,
    },
    {
      id: 605,
      nameDe: 'Gemüsesuppe',
      nameEn: 'Gemüsesuppe',
      nameZn: '蔬菜汤',
      categories: ['all', 'soup'],
      descriptionDe: 'Mit Glasnudeln und Tofu',
      descriptionEn: 'Vegetable soup with glass noodles and tofu',
      descriptionZn: 'Vegetable soup with glass noodles and tofu',
      imgSrc: 'images/takeway/605.jpeg',
      price: 11.8,
      show: true,
    },
    {
      id: 606,
      nameDe: 'Sauer Scharf Suppe',
      nameEn: 'Sauer Scharf Suppe',
      nameZn: '酸辣汤',
      categories: ['all', 'soup'],
      descriptionDe:
        'Mit Bambussprossen, Mu-Er-Pilzen, Champignons, Eiern, Sesamöl und weißem Pfeffer',
      descriptionEn:
        'With eggs, wood ear fungus, bamboo shoots, tofu, sesame oil and white pepper (Beijing cuisine, slightly spicy)',
      descriptionZn:
        'With eggs, wood ear fungus, bamboo shoots, tofu, sesame oil and white pepper (Beijing cuisine, slightly spicy)',
      imgSrc: 'images/takeway/606.jpeg',
      price: 11.8,
      show: true,
    },
    {
      id: 607,
      nameDe: 'Wan-Tan Suppe',
      nameEn: 'Wan-Tan Suppe',
      nameZn: '云吞汤',
      categories: ['all', 'soup'],
      descriptionDe:
        'Wan-Tan gefüllt mit Hühnerfleisch und Frühlingszwiebeln in Kraftbrühe',
      descriptionEn:
        'Wonton filled with chicken meat and spring onions served in broth',
      descriptionZn:
        'Wonton filled with chicken meat and spring onions served in broth',
      imgSrc: 'images/takeway/607.jpeg',
      price: 12.8,
      show: true,
    },
    {
      id: 701,
      nameDe: 'Gebratener Reis mit Sojasauce',
      nameEn: 'Gebratener Reis mit Sojasauce',
      nameZn: '酱油炒饭',
      categories: ['all', 'main'],
      descriptionDe: 'Mit Eier und Gemüse (vegetarisch)',
      descriptionEn:
        'fried rice with eggs and seasonal vegetables (vegetarian)',
      descriptionZn:
        'fried rice with eggs and seasonal vegetables (vegetarian)',
      imgSrc: 'images/takeway/701.jpeg',
      price: 10.8,
      show: true,
    },
    {
      id: 702,
      nameDe: 'Yang Zhou Gebratener Reis',
      nameEn: 'Yang Zhou Gebratener Reis',
      nameZn: '扬州炒饭',
      categories: ['all', 'main'],
      descriptionDe:
        'Gebratener Reis mit Ei, Garnelen, Hühnerfleisch und Gemüse',
      descriptionEn:
        'Fired rice with eggs, chicken meat, shrimps and vegetables',
      descriptionZn:
        'Fired rice with eggs, chicken meat, shrimps and vegetables',
      imgSrc: 'images/takeway/702.jpeg',
      price: 13.8,
      show: true,
    },
    {
      id: 704,
      nameDe: 'Maultaschen',
      nameEn: 'Maultaschen',
      nameZn: '水饺',
      categories: ['all', 'main'],
      descriptionDe:
        'Gekochte Maultaschen mit Schweinefleisch- und Gemüsenfüllung (15 St.)',
      descriptionEn:
        'Cooked Chinese dumplings with pork and vegetable filling (15 pcs.)',
      descriptionZn:
        'Cooked Chinese dumplings with pork and vegetable filling (15 pcs.)',
      imgSrc: 'images/takeway/704.jpeg',
      price: 14.8,
      show: true,
    },
    {
      id: 705,
      nameDe: 'Guo Tie',
      nameEn: 'Guo Tie',
      nameZn: '锅贴（15个）',
      categories: ['all', 'dessert', 'main'],
      descriptionDe:
        'In der Pfanne gebratene Maultaschen gefüllt mit Schweinefleisch und Gemüse, serviert mit Essig-Sojasoße-Sesamöl Dip',
      descriptionEn:
        'Pan-fried dumplings filled with ground pork, Chinese cabbage and green onion served with homemade dipping sauce',
      descriptionZn:
        'Pan-fried dumplings filled with ground pork, Chinese cabbage and green onion served with homemade dipping sauce',
      imgSrc: 'images/takeway/705.jpeg',
      price: 14.8,
      show: true,
    },
    {
      id: 706,
      nameDe: 'Gebratene Nudeln',
      nameEn: 'Gebratene Nudeln',
      nameZn: '素炒面',
      categories: ['all', 'main'],
      descriptionDe: 'mit Eier und Gemüse (vegetarisch)',
      descriptionEn:
        'Fried noodle with eggs and seasonal vegetables (vegetarian)',
      descriptionZn:
        'Fried noodle with eggs and seasonal vegetables (vegetarian)',
      imgSrc: 'images/takeway/706.jpeg',
      price: 9.8,
      show: true,
    },
    {
      id: 707,
      nameDe: 'Pfannkuchen',
      nameEn: 'Pfannkuchen',
      nameZn: '葱油饼',
      categories: ['all', 'main'],
      descriptionDe:
        'Leicht herzhafte Chinesische Pfannkuchen mit Frühlingszwiebeln',
      descriptionEn:
        'Chinese savory, unleavened flat bread folded with oil and minced green onions',
      descriptionZn:
        'Chinese savory, unleavened flat bread folded with oil and minced green onions',
      imgSrc: 'images/takeway/707.jpeg',
      price: 9.8,
      show: true,
    },
    {
      id: 709,
      nameDe: 'Nudelsuppe mit Rindergulasch',
      nameEn: 'Nudelsuppe mit Rindergulasch',
      nameZn: '牛肉汤面',
      categories: ['all', 'main'],
      descriptionDe:
        'Würzige Nudelsuppe mit geschmortem Rindfleisch und Koriander (scharf)',
      descriptionEn: 'Noodle soup with braised beef (spicy)',
      descriptionZn: 'Noodle soup with braised beef (spicy)',
      imgSrc: 'images/takeway/709.jpeg',
      price: 11.9,
      hot: 1,
      show: true,
    },
    {
      id: 710,
      nameDe: 'Zhajiang Nudeln',
      nameEn: 'Zhajiang Nudeln',
      nameZn: '老北京炸酱面',
      categories: ['all', 'main'],
      descriptionDe: 'Gekochte Nudeln serviert mit Sojabohnenpaste und Gurke',
      descriptionEn:
        'Cooked noodles topped with cucumber and Zhejiang sauce made of simmering stir-fired ground pork with salty fermented soybean paste',
      descriptionZn:
        'Cooked noodles topped with cucumber and Zhejiang sauce made of simmering stir-fired ground pork with salty fermented soybean paste',
      imgSrc: 'images/takeway/710.jpeg',
      price: 11.8,
      show: true,
    },
    {
      id: 801,
      nameDe: 'Kürbiskuchen',
      nameEn: 'Kürbiskuchen',
      nameZn: '南瓜饼',
      categories: ['all', 'dessert', 'main'],
      descriptionDe: 'Gebackene Kürbiskuchen (4 St.) ',
      descriptionEn: 'Deep fried pumpkin mocha cake (4 pcs.)',
      descriptionZn: 'Deep fried pumpkin mocha cake (4 pcs.)',
      imgSrc: 'images/takeway/801.jpeg',
      price: 5.8,
      show: true,
    },
    {
      id: 802,
      nameDe: 'Sesambällchen',
      nameEn: 'Sesambällchen',
      nameZn: '芝麻球',
      categories: ['all', 'dessert', 'main'],
      descriptionDe: 'Gebackene Sesambällchen (6 St.)',
      descriptionEn: 'Deep fried sesame balls (6 pcs.)',
      descriptionZn: 'Deep fried sesame balls (6 pcs.)',
      imgSrc: 'images/takeway/802.jpeg',
      price: 5.8,
      show: true,
    },
    {
      id: 803,
      nameDe: 'Mini Frühlingsrollen',
      nameEn: 'Mini Frühlingsrollen',
      nameZn: '小春卷',
      categories: ['all', 'dessert', 'main'],
      descriptionDe: 'Frühlingsrollen gefüllt mit Gemüse',
      descriptionEn:
        'Deep fried spring rolls filled with cabbage served with dipping sauce',
      descriptionZn:
        'Deep fried spring rolls filled with cabbage served with dipping sauce',
      imgSrc: 'images/takeway/803.jpeg',
      price: 4.9,
      show: true,
    },
    {
      id: 804,
      nameDe: 'HaKouw',
      nameEn: 'HaKouw',
      nameZn: '虾饺',
      categories: ['all', 'dessert', 'main'],
      descriptionDe: 'Gedämpfte Teigtaschen mit Garnelenfüllung',
      descriptionEn:
        'Steamed shrimp dumplings with prawn filling, served as Dim Sum',
      descriptionZn:
        'Steamed shrimp dumplings with prawn filling, served as Dim Sum',
      imgSrc: 'images/takeway/804.jpeg',
      price: 5.8,
      show: true,
    },
    {
      id: 805,
      nameDe: 'SiuMai',
      nameEn: 'SiuMai',
      nameZn: '烧麦',
      categories: ['all', 'dessert', 'main'],
      descriptionDe:
        'Gedämpfte Teigtaschen mit Garnelen- und Schweinefleischfüllung',
      descriptionEn:
        'Steamed dumplings with shrimp and ground pork filling, served as Dim Sum',
      descriptionZn:
        'Steamed dumplings with shrimp and ground pork filling, served as Dim Sum',
      imgSrc: 'images/takeway/805.jpeg',
      price: 5.8,
      show: true,
    },
  ];

  RICE_ITEM: OrderItem = {
    id: 703,
    nameDe: 'Reis',
    nameEn: 'Reis',
    nameZn: '米饭',
    descriptionDe: '',
    descriptionEn: '',
    descriptionZn: '',
    quantity: 1,
    price: 1,
  };

  // DELIVERY_PLZ = {
  //     PLZ_1: ["80331", "80333", "80335", "80336", "80337", "80339", "80469", "80539", "80634", "80636", "80637", "80638", "80639",
  //         "80686", "80687", "80689", "80796", "80797", "80798", "80799", "81369", "81371", "81373", "81375", "81377"],
  //     PLZ_2: ["80538", "80801", "80802", "80803", "80804", "80805", "80807", "80809", "80933", "80935", "80937", "80939", "80992",
  //         "80993", "80995", "80997", "80999", "81241", "81243", "81245", "81247", "81249", "81375", "81377", "81379", "81475",
  //         "81476", "81477", "81479", "81539", "81541", "81543", "81545", "81547", "81549", "81667", "81669", "81671", "81673",
  //         "81675", "81677", "81679", "81735", "81737", "81739", "81825", "81827", "81829", "81925", "81927", "81929",
  //         "85609", "85748", "85757", "85764", "85774"],
  //     PLZ_3: []
  // };
  //
  // DELIVERY_FEE_PLZ = {
  //     PLZ_1: 4.99,
  //     PLZ_2: 8.99,
  //     PLZ_3: 12.99
  // }
}

const dataCenter = new DataCenter();

export default dataCenter;
